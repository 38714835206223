@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-fluid;
  @apply scroll-smooth;
}

body {
  @apply font-poppins;
}

:target {
  @apply -mt-[6.25rem] pt-[6.25rem];
}
